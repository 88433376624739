@import '@/styles/vars';

.header-desktop {
  &--container {
    height: 66px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw !important;
    background: rgba(255, 255, 255, 0.06);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(25px);

    z-index: 100 !important;
  }

  &--inner {
    height: 66px !important;
    padding: 0 160px !important;

    .wallet--main-btn {
      font-size: 15px;
      font-weight: 700;
      padding: 8px 32px;
      height: 37px;
      border: none;
      border-radius: 4px;
      color: $TEXT_COLOR_DARK !important;
      background-color: $PRIMARY !important;
    }
  }
}

.header-mobile {
  width: 100% !important;
  height: 56px !important;
  background: #141417;
  padding: 0 20px;

  &--btn-switch {
    color: $TEXT_COLOR_PRIMARY !important;

    &:hover {
      color: $PRIMARY !important;
    }
  }
}

// Header Menu Navigation
.header--menu-nav--desktop {
  width: 100%;
  justify-content: center !important;
  background: transparent !important;
  border-bottom: transparent !important;
  gap: 20px;

  &:after,
  &:before {
    display: none !important;
  }

  .space-3-menu-item,
  .space-3-menu-submenu {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 6px;
    color: $TEXT_COLOR_PRIMARY !important;
    height: 66px !important;
    overflow: hidden !important;
    .space-3-icon {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    &:hover {
      .space-3-icon svg {
        color: $PRIMARY;
      }
      .space-3-menu-title-content {
        color: $PRIMARY;
      }
    }

    &:after {
      left: 6px !important;
      right: 6px !important;
    }

    .space-3-menu-title-content {
      margin-inline-start: unset !important;
    }

    .space-3-menu-title-content > a {
      color: inherit !important;
    }
  }

  .space-3-menu-item-selected,
  .space-3-submenu-item-selected {
    background: transparent !important;
    color: $PRIMARY !important;
    .space-3-icon {
      color: $PRIMARY !important;
      svg {
        color: $PRIMARY !important;
      }
    }
    .space-3-menu-title-content {
      color: $PRIMARY !important;
    }
    &:before {
      position: absolute !important;
      content: '';
      width: 60px;
      height: 20px;
      display: block !important;
      bottom: -14px;
      left: 50%;
      background: $PRIMARY;
      opacity: 0.3;
      filter: blur(11.5px);
      border-radius: 80px;
      transform: translateX(-50%);
    }
  }
}

.header--menu-nav--mobile {
  border-inline-end: none !important;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .space-3-menu-item {
    padding: 0 12px 0 0;
    display: flex;
    align-items: center;
    border-radius: 0;
    width: fit-content;
    border-bottom: 1px solid transparent !important;
    height: 50px;
    gap: 6px;

    &-icon svg {
      width: 20px !important;
      height: 20px !important;
    }

    &:hover,
    &-selected {
      background: transparent !important;
      border-bottom: 1px solid $PRIMARY !important;

      svg {
        color: $PRIMARY !important;
      }

      .space-3-menu-title-content {
        color: $PRIMARY !important;
      }
    }

    .space-3-menu-title-content {
      margin-inline-start: unset !important;
    }

    &:hover:has(.disable-active),
    &-selected:has(.disable-active) {
      border-bottom: transparent !important;
    }
  }
}
