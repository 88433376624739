@import '@/styles/vars';

.app-container {
  width: 100%;
  min-height: calc(100vh - 64px);

  max-width: 1230px !important;
  margin: 0 auto !important;

  padding: 0 24px; //padding left and right 24px to prevent Row - gap scroll

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 0;
    max-width: 100vw !important;
  }
}
