$PRIMARY: #0fdbd1;
$SECONDARY: #4d5a66;

$SUCCESS: #0fdbd1;
$WARNING: #ff7300;
$DANGER: #db4646;

$TEXT_COLOR_PRIMARY: #ffffff;
$TEXT_COLOR_DARK: #1e1e1e;

$BG_CONTENT: #121416;

$BG_CONTENT_LIGHT: #1c2023;

$TRANSITION_MAIN: all 0.3s ease-in-out;

$MAX_WIDTH_MOBILE: 991px;
